import * as fuelCappingActions from '../actions/actionFuelCapping';
import { EXTERNAL_SUPPLY_RUN_TYPE } from '../constants/fms';
import { STANDARD } from '../containers/views/fms/data-management/fuel-capping/components/fuel-capping-calculation-page/components/offshore-operation-section/constants';
import { getESRValue, getNumber, isNumber } from '../services/helper';
import { isEmpty, isNaN, isNil } from 'lodash';

const defaultState = {
  fuelCappingPage: {
    enroute_section_full: [],
    enroute_section_selected: [],
    enroute_section_grand_total: {
      accumulatedDistance: 0,
      avgSpeed: 0,
      avgBenchmark: 0,
      accumulatedInterField: 0,
    },
    offshore_operation_section: {
      offshore: {
        fo_consumption_type: STANDARD,
        operation_days: 0,
        daily_average_benchmark: 0,
      },
      critical: {
        fo_consumption_type: STANDARD,
        operation_days: 0,
        daily_average_benchmark: 'Unable to support Critical Op',
      },
      dpmode: {
        fo_consumption_type: STANDARD,
        operation_days: 0,
        daily_average_benchmark: 'No DP Mode',
      },
    },
    buffer_section: {
      emergency_buffer: 0,
      total: 0,
    },
    externalSupplyRunSection: {
      supplyToPlatform: 0,
      supplyToRig: 0,
      awb: 0,
      gpv: 0,
      fcb: 0,
      ahts: 0,
    },
    externalSupplyRunTypes: [],
    external_supply_run_save_data: [],
    plt_voyage_id: undefined,
    header_id: undefined,
    summary_list: [],
    isHistorical: false,
    default_standard_operation_days: 0,
    linked_fuel_request_id: null,
    linked_meter_ticket_id: null
  },
};

const REDUCERS = {
  [fuelCappingActions.GET_FUEL_CAPPING_SECTIONS_DATA]: (state, { payload }) => {
    if (!isEmpty(payload)) {
      let tempTotalData = {
        accumulatedDistance: 0,
        avgSpeed: 0,
        avgBenchmark: 0,
        accumulatedInterField: 0,
      };
      if (!isNil(payload.header_id)) {
        const enrouteData = payload.enroute_section ?? [];
        enrouteData.forEach((item, idx) => {
          tempTotalData = {
            ...tempTotalData,
            accumulatedDistance: tempTotalData['accumulatedDistance'] + item.accumulatedDistance,
            accumulatedInterField: tempTotalData['accumulatedInterField'] + item.accumulatedInterField,
          };
          if (idx !== enrouteData.length - 1) {
            tempTotalData = {
              ...tempTotalData,
              avgSpeed: tempTotalData['avgSpeed'] + item.avgSpeed,
              avgBenchmark: tempTotalData['avgBenchmark'] + item.avgBenchmark,
            };
          } else {
            tempTotalData = {
              ...tempTotalData,
              avgSpeed: (tempTotalData['avgSpeed'] + item.avgSpeed) / enrouteData.length,
              avgBenchmark: (tempTotalData['avgBenchmark'] + item.avgBenchmark) / enrouteData.length,
            };
          }
        });
      }
      return {
        ...state,
        fuelCappingPage: {
          ...state.fuelCappingPage,
          enroute_section_full: payload.enroute_section,
          enroute_section_selected: payload.header_id ? payload.enroute_section : [],
          enroute_section_grand_total: tempTotalData,
          offshore_operation_section:
            payload.offshore_operation_section ?? defaultState.fuelCappingPage.offshore_operation_section,
          buffer_section: payload.buffer_section ?? {
            emergency_buffer: 0,
            total: 0,
          },
          plt_voyage_id: payload.plt_voyage_id || undefined,
          isHistorical: !isNil(payload.header_id),
          linked_fuel_request_id: payload.linked_fuel_request_id,
          linked_meter_ticket_id: payload.linked_meter_ticket_id
        },
      };
    } else {
      return {
        ...state,
        fuelCappingPage: {
          ...state.fuelCappingPage,
          enroute_section_full: [],
          enroute_section_selected: [],
          enroute_section_grand_total: {
            accumulatedDistance: 0,
            avgSpeed: 0,
            avgBenchmark: 0,
            accumulatedInterField: 0,
          },
          offshore_operation_section: {},
          buffer_section: {
            emergency_buffer: 0,
            total: 0,
          },
        },
        plt_voyage_id: undefined,
      };
    }
  },
  [fuelCappingActions.FUEL_CAPPING_SELECT_VOYAGES]: (state, { payload }) => {
    let tempTotalData = {
      accumulatedDistance: 0,
      avgSpeed: 0,
      avgBenchmark: 0,
      accumulatedInterField: 0,
    };

    let tempOffshoreOperationData = {
      offshore: {
        fo_consumption_type: STANDARD,
        operation_days: 0,
        daily_average_benchmark: 0,
      },
      critical: {
        fo_consumption_type: STANDARD,
        operation_days: 0,
        daily_average_benchmark: 'Unable to support Critical Op',
      },
      dpmode: {
        fo_consumption_type: STANDARD,
        operation_days: 0,
        daily_average_benchmark: 'No DP Mode',
      },
    };

    let tempDefaultOperationDays = 0
    const selectedVoyagesData = state.fuelCappingPage.enroute_section_full?.filter((item) =>
      payload.includes(item.trip_id)
    );
    selectedVoyagesData.forEach((item, idx) => {
      tempTotalData = {
        ...tempTotalData,
        accumulatedDistance: tempTotalData['accumulatedDistance'] + item.accumulatedDistance,
        accumulatedInterField: tempTotalData['accumulatedInterField'] + item.accumulatedInterField,
      };
      if (idx !== selectedVoyagesData.length - 1) {
        tempTotalData = {
          ...tempTotalData,
          avgSpeed: tempTotalData['avgSpeed'] + item.avgSpeed,
          avgBenchmark: tempTotalData['avgBenchmark'] + item.avgBenchmark,
        };
      } else {
        tempTotalData = {
          ...tempTotalData,
          avgSpeed: (tempTotalData['avgSpeed'] + item.avgSpeed) / selectedVoyagesData.length,
          avgBenchmark: (tempTotalData['avgBenchmark'] + item.avgBenchmark) / selectedVoyagesData.length,
        };
      }

      //Calculate Offshore Operation data
      if (isNil(state.fuelCappingPage.header_id)) {
        const firstTrip = item.voyageData[0];
        const vesselStandardRateAvailable = firstTrip.vessel_standard_rate_available
        const tempOffshoreData = tempOffshoreOperationData.offshore;
        const tempCriticalData = tempOffshoreOperationData.critical;
        const tempDpmodeData = tempOffshoreOperationData.dpmode;
        tempDefaultOperationDays += firstTrip.cycle_time
        tempOffshoreOperationData = {
          offshore: {
            fo_consumption_type: tempOffshoreData.fo_consumption_type,
            operation_days: vesselStandardRateAvailable ? tempOffshoreData.operation_days + firstTrip.cycle_time : 0,
            daily_average_benchmark:
              tempOffshoreData.daily_average_benchmark + getNumber(firstTrip.daily_fo_consumption_standard_rate),
          },
          critical: {
            fo_consumption_type: tempCriticalData.fo_consumption_type,
            operation_days: vesselStandardRateAvailable && isNumber(firstTrip.critical_ops_standard_rate) ? tempCriticalData.operation_days + firstTrip.cycle_time : 0,
            daily_average_benchmark: !isNumber(firstTrip.critical_ops_standard_rate)
              ? firstTrip.critical_ops_standard_rate
              : getNumber(tempCriticalData.daily_average_benchmark) + getNumber(firstTrip.critical_ops_standard_rate),
          },
          dpmode: {
            fo_consumption_type: tempDpmodeData.fo_consumption_type,
            operation_days: vesselStandardRateAvailable && isNumber(firstTrip.dp_mode_daily_fo_consumption_standard_rate) ? tempDpmodeData.operation_days + firstTrip.cycle_time : 0,
            daily_average_benchmark: !isNumber(firstTrip.dp_mode_daily_fo_consumption_standard_rate)
              ? firstTrip.dp_mode_daily_fo_consumption_standard_rate
              : getNumber(tempDpmodeData.daily_average_benchmark) +
                getNumber(firstTrip.dp_mode_daily_fo_consumption_standard_rate),
          },
        };
      } else {
        tempOffshoreOperationData = state.fuelCappingPage.offshore_operation_section;
      }
    });

    return {
      ...state,
      fuelCappingPage: {
        ...state.fuelCappingPage,
        enroute_section_selected: selectedVoyagesData,
        enroute_section_grand_total: tempTotalData,
        offshore_operation_section: tempOffshoreOperationData,
        default_standard_operation_days: tempDefaultOperationDays
      },
    };
  },
  [fuelCappingActions.FUEL_CAPPING_SAVE_HEADERID]: (state, { payload }) => {
    return {
      ...state,
      fuelCappingPage: {
        ...state.fuelCappingPage,
        header_id: payload,
      },
    };
  },
  [fuelCappingActions.FUEL_CAPPING_SAVE_BUFFER_SECTION]: (state, { payload }) => {
    return {
      ...state,
      fuelCappingPage: {
        ...state.fuelCappingPage,
        buffer_section: payload,
      },
    };
  },
  [fuelCappingActions.FUEL_CAPPING_SAVE_OFFSHORE_SECTION]: (state, { payload }) => {
    return {
      ...state,
      fuelCappingPage: {
        ...state.fuelCappingPage,
        offshore_operation_section: payload,
      },
    };
  },
  [fuelCappingActions.FUEL_CAPPING_SAVE_ESR_SECTION]: (state, { payload }) => {
    return {
      ...state,
      fuelCappingPage: {
        ...state.fuelCappingPage,
        external_supply_run_save_data: payload,
      },
    };
  },
  [fuelCappingActions.UPDATE_FUEL_CAPPING_OFFSHORE_OPERATION]: (state, { payload }) => {
    return {
      ...state,
    };
  },
  [fuelCappingActions.UPDATE_FUEL_CAPPING_BUFFER_SECTION]: (state, { payload }) => {
    return {
      ...state,
    };
  },
  [fuelCappingActions.GET_FUEL_CAPPING_EXTERNAL_SUPPLY_RUN_DATA]: (state, { payload }) => {
    const { SUPPLY_TO_PLATFORM, SUPPLY_TO_RIG, AWB, GPV, FCB, AHTS } = EXTERNAL_SUPPLY_RUN_TYPE;

    if (state.fuelCappingPage.externalSupplyRunTypes.length > 0) {
      const ESRTypes = state.fuelCappingPage.externalSupplyRunTypes.map((item) => ({
        label: item.code_name,
        id: item.code_id,
      }));
      return {
        ...state,
        fuelCappingPage: {
          ...state.fuelCappingPage,
          externalSupplyRunSection: {
            supplyToPlatform: getESRValue(payload, ESRTypes, SUPPLY_TO_PLATFORM.label) || 0,
            supplyToRig: getESRValue(payload, ESRTypes, SUPPLY_TO_RIG.label) || 0,
            awb: getESRValue(payload, ESRTypes, AWB.label) || 0,
            gpv: getESRValue(payload, ESRTypes, GPV.label) || 0,
            fcb: getESRValue(payload, ESRTypes, FCB.label) || 0,
            ahts: getESRValue(payload, ESRTypes, AHTS.label) || 0,
          },
        },
      };
    } else return { ...state };
  },
  [fuelCappingActions.GET_FUEL_CAPPING_EXTERNAL_SUPPLY_RUN_TYPES]: (state, { payload }) => {
    return {
      ...state,
      fuelCappingPage: {
        ...state.fuelCappingPage,
        externalSupplyRunTypes: [...payload],
      },
    };
  },
  [fuelCappingActions.GET_FUEL_CAPPING_SUMMARY]: (state, { payload }) => {
    return {
      ...state,
      summary: payload,
    };
  },
  [fuelCappingActions.GET_FUEL_CAPPING_SUMMARY_LIST]: (state, { payload }) => {
    return {
      ...state,
      fuelCappingPage: {
        ...state.fuelCappingPage,
        summary_list: payload,
      },
    };
  },
  [fuelCappingActions.RESET_FUEL_CAPPING_ENROUTE_SECTION]: (state, { payload }) => {
    return {
      ...state,
      fuelCappingPage: {
        ...state.fuelCappingPage,
        enroute_section_full: defaultState.fuelCappingPage.enroute_section_full,
        enroute_section_selected: defaultState.fuelCappingPage.enroute_section_selected
      },
    };
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = defaultState, { type, payload, error }) => {
  if (REDUCERS[type]) {
    return REDUCERS[type](state, { payload, error });
  }
  return state;
};

export const FUEL_CAPPING_PAGE_REDUCER = 'fuelCappingPageReducer';
